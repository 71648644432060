.date {
    label {
        color: rgb(61, 61, 61);
        top: -8px;
    }

    border-radius: 30px !important;

}

.MuiInputBase-input {
    height: 8px !important;
    border-radius: 30px !important;
}

.MuiOutlinedInput-root {
    border-radius: 30px !important;

}

.ant-table-content {
    box-shadow: 0px 0px 6px 0px rgba(54, 54, 54, 0.75) !important;
}

.red-row {
    background-color: #feb8b8 !important;
}



.ant-table-cell-row-hover {
    background-color: rgb(202, 223, 255) !important;
}

.red-row {
    .ant-table-cell-row-hover {
        background-color: #feb8b8 !important;
    }
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
    border-start-end-radius: 0px;

}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
    border-start-start-radius: 0px;

}

@media (min-width: 0) {
    .css-1t4mj1a-MuiStack-root {
        margin-top: 0!important;
        align-items: center!important;
        -webkit-flex-direction: row!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important;
    }
    .css-3rdt7a{
        margin-top: 0!important;
        align-items: center!important;
        -webkit-flex-direction: row!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important;
    }
}
@media (min-width: 0px) {
    .css-1t4mj1a-MuiStack-root>:not(style)~:not(style) {
        margin-top: 0px!important;
    }
    .css-3rdt7a > :not(style) ~ :not(style){
        margin-top: 0px!important;
    }
}