
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.ant-menu-item {
    color: #706f6f !important;
    font-size: 18px !important;
    font-weight: 500;
    text-align: start;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    .ant-menu-item-icon{
        font-size: 18px !important;
    }
}

.ant-menu-item-selected {
    background-color: #fff !important;
    border-left: 10px solid #2D60FF !important;
    color: #2D60FF !important;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {

    background-color: #4169df !important;
    color: #fff !important;
}
.ant-table .ant-table-container .ant-table-content table tbody.ant-table-tbody .ant-table-row .ant-table-cell {
    font-size: 16px!important;
    font-weight: 500!important;
    color: #232323!important;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.header-right-button {
    width: 42px !important;
    border: 1px solid rgb(237, 240, 247);
    background-color: rgb(237, 240, 247);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    color: #706f6f;
    margin-right: 15px;
    border-radius: 30px;
    padding: 20px;
}