.login-all {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .container-login {


        width: 350px;
        height: 450px;
        align-content: center;
        padding: 30px;
        box-shadow: 1px 6px 10px 0px rgba(0, 0, 0, 0.75);

        .logo-login {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100px;
            }
        }

        .loginLabel {
            font-size: 16px;
            font-weight: 500;
        }

        .loginInput {
            height: 40px;
            margin: 5px 5px 5px 0px;
            font-size: 16px;
        }

        .errormessage {
            color: red;
            font-weight: bold;
        }

        .loginButton {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                width: 350px;
                height: 40px;
                font-size: 16px;
                margin-top: 20px;
                background-color: #1814F3;
                color: white;
            }
        }
    }
}