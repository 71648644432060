.fullScreenLoading{
  display: flex;
  align-items: center;
  justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, .4);
  
    .ant-spin{
      width: 48px;
      height: 48px;
  
      .anticon{
        width: 100%;
        height: 100%;
  
        svg{
          width: 100%;
          height: 100%;
  
          path{
            fill: #fff;
          }
        }
      }
    }
  }