.fingerIcon {
    transform: rotate(180deg) !important;
}

.qrStyle * {
    padding: 0;
}

.allQrs * {
    padding: 0;
    margin: 0;
}